@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.elementToFadeInAndOut {
  -webkit-animation: fadeinout 5s linear forwards;
  animation: fadeinout 5s linear forwards;
}

.elementToFadeIn {
  -webkit-animation: fadein 3s linear forwards;
  animation: fadein 3s linear forwards;
}

@-webkit-keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@keyframes fadeinout {
  0%,100% { opacity: 0; }
  50% { opacity: 1; }
}

@-webkit-keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@keyframes fadein {
  0% { opacity: 0; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}


.documentos-citas-btn a{height: 48px; line-height: 46px; padding: 0px 15px; border-radius: 50px; font-size: 14px; color: #fff; min-width: 120px; text-align: center;} 
.documentos-citas-btn a.documentos-btn{background: #2eddfd;}
.documentos-citas-btn a.citas-btn{background: #0055ac;}

.my-document-bg{padding: 50px 0px;}
.my-document-head{margin-bottom: 15px}
.my-document-head h1{font-weight: 700; font-size: 32px; color:#000;}
.documentos-btn{display: flex; justify-content: center;}
.documentos-add-btn{display: flex;}
.documentos-add-btn a{display: flex; align-items: center; background: #90ee90; padding: 5px 10px; border-radius: 50px; font-size: 14px; }
.documentos-add-btn a i{margin-right: 5px; color:#000}
.documentos-btn a{display: flex; align-items: center; background: #000; padding: 5px 10px; border-radius: 50px; font-size: 14px; color:#fff;}
.documentos-add-btn a svg{color:#000}
.documentos-btn a i{margin-right: 5px;}
.table-bg{overflow: auto;}
.table{width: 100%;}
.table-bg tr th{background: linear-gradient(to right,  #0055ae 0%,#00a9f6 100%); padding:15px 10px; text-align: center; font-size: 16px; color: #fff; font-weight: 700;}
.table-bg tr th:first-child{border-radius: 20px 0px 0px 0px;}
.table-bg tr th:last-child{border-radius: 0px 20px 0px 0px;}
.table-bg tr td{padding: 15px 10px; text-align: center; color: #000; font-size: 16px; background: #fff; border-bottom: solid 1px #efefef;}
.category-dropdown button{background: url(images/drop-arrow.svg) no-repeat right center; padding-right: 25px;}


.category-dropdown .border-gray-300 {
    border: none !important;
    --tw-border-opacity: unset !important;
    border-color: unset !important;
}

.category-dropdown .rounded-md {
    border-radius: unset !important;
}



























